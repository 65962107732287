import {Moment} from 'moment';
import * as moment from 'moment';

export class StationStateDtoMapper {

    public mapDto(dto: IStationStateDto): IStationState {
        return {
            roadTrafficLight: this.mapAlarmActuatorState(dto.roadTrafficLight),
            railwayTrafficLight: this.mapAlarmActuatorState(dto.railwayTrafficLight),
            gravelQuarrySiren: this.mapAlarmActuatorState(dto.gravelQuarrySiren)
        };
    }

    private mapAlarmActuatorState(dto: IAlarmActuatorStateDto): IAlarmActuatorState {
        return {
            timestamp: moment(dto.timestamp),
            alarmStateKnown: dto.alarmStateKnown,
            activeAlarms: dto.activeAlarms.map(a => this.mapAlarmType(a)),
            resetRequested: dto.resetRequested != null ? this.mapStateChangeRequest(dto.resetRequested) : null
        };
    }

    private mapStateChangeRequest(dto: IStateChangeRequestDto): IStateChangeRequest {
        return {
            timestamp: moment(dto.timestamp),
            requestedBy: dto.requestedBy
        };
    }

    private mapAlarmType(rawAlarmType: string): AlarmType {
        return AlarmType[rawAlarmType as keyof typeof AlarmType];
    }
}

export interface IStationStateDto {
    roadTrafficLight: IAlarmActuatorStateDto;
    railwayTrafficLight: IAlarmActuatorStateDto;
    gravelQuarrySiren: IAlarmActuatorStateDto;
}

export interface IAlarmActuatorStateDto {
    timestamp: string;
    alarmStateKnown: boolean;
    activeAlarms: string[];
    resetRequested: IStateChangeRequestDto | null;
}

export interface IStateChangeRequestDto {
    timestamp: string;
    requestedBy: string;
}

export interface IStationState {
    roadTrafficLight: IAlarmActuatorState;
    railwayTrafficLight: IAlarmActuatorState;
    gravelQuarrySiren: IAlarmActuatorState;
}

export interface IAlarmActuatorState {
    timestamp: Moment;
    alarmStateKnown: boolean;
    activeAlarms: AlarmType[];
    resetRequested: IStateChangeRequest | null;
}

export interface IStateChangeRequest {
    timestamp: Moment;
    requestedBy: string;
}

export enum AlarmType {
    AvalancheMovement,
    AvalancheInDangerZone,
    Sensalpin
}

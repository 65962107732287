import Vue from 'vue';
import {Inject, Prop} from 'vue-property-decorator';
import {AlarmType, IAlarmActuatorState, IStationState} from './station-state-dto-mapper';
import Component from 'vue-class-component';
import {ApiClient} from './api-client';

@Component({
    template: require('./alarm-reset-panel.html')
})
export class AlarmResetPanel extends Vue {

    @Prop()
    public stationState: IStationState;

    @Prop()
    private alarmResetEnabled: boolean;

    @Inject()
    public apiClient: ApiClient;

    public isAlarmActive() {
        return this.getAlarmActuatorStates().some(s => s.alarmStateKnown && s.activeAlarms.length > 0);
    }

    public isResetAllowed() {

        let blockedStates = [AlarmType.AvalancheMovement];

        let activeAlarmTypes =  ([] as AlarmType[]).concat(...this.getAlarmActuatorStates().map(s => s.activeAlarms));

        let noResetBlockingStates = activeAlarmTypes.every(t => blockedStates.indexOf(t) === -1);

        return this.isAlarmActive() && noResetBlockingStates;
    }

    public isResetInProgress() {

        return this.getAlarmActuatorStates().every(s => s.resetRequested !== null);
    }

    public resetAlarm() {
        this.apiClient.requestAlarmReset();
    }

    private getAlarmActuatorStates(): IAlarmActuatorState[] {
        return [
            this.stationState.roadTrafficLight,
            this.stationState.railwayTrafficLight,
            this.stationState.gravelQuarrySiren];
    }
}


import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {AlarmType, IAlarmActuatorState} from './station-state-dto-mapper';

@Component({
    template: require('./alarm-actuator-panel.html')
})
export class AlarmActuatorPanel extends Vue {

    @Prop()
    public actuatorName: string;

    @Prop()
    public icon: string;

    @Prop()
    private actuatorState: IAlarmActuatorState;

    constructor() {
        super();
    }

    public get alertClass() {

        if (!this.actuatorState || !this.actuatorState.alarmStateKnown) {
            return 'alert-secondary';
        }

        return this.actuatorState.activeAlarms.length === 0 ? 'alert-success' : 'alert-danger';
    }

    public stateText(alarmType: AlarmType) {
        switch (alarmType) {
            case AlarmType.AvalancheMovement:
                return 'Lawine in Bewegung';
            case AlarmType.AvalancheInDangerZone:
                return 'Lawine in Gefahrenbereich';
            case AlarmType.Sensalpin:
                return 'Sensalpin-Alarm';
        }
    }
}

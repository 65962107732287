import './station-state-logs-panel.scss';

import Component from 'vue-class-component';
import Vue from 'vue';
import {Inject} from 'vue-property-decorator';
import {Moment} from 'moment';
import * as moment from 'moment';
import {StationStateTracker} from './station-state-tracker';
import {AlarmType, IAlarmActuatorState, IStationState} from './station-state-dto-mapper';
import {ConnectionTracker} from './connection-tracker';
import {StationStateLogsDtoMapper, IStationStateLog} from './station-state-logs-dto-mapper';

@Component({
    template: require('./station-state-logs-panel.html')
})
export class StationStateLogsPanel extends Vue {

    @Inject()
    private stationStateTracker: StationStateTracker;

    @Inject()
    private connectionTracker: ConnectionTracker;

    @Inject()
    private stationStateLogsDtoMapper: StationStateLogsDtoMapper;

    private logs: { [timestamp: string]: IStationStateLog; } = {};

    private now: Moment = moment();

    constructor() {
        super();

        this.stationStateTracker.registerStationStateCallback((state, isInitial) => this.addNewLog(state, isInitial));

        this.connectionTracker.registerHandler(
            "StationStateLogs",
                dto => this.updateAlarmStateLogs(this.stationStateLogsDtoMapper.mapDto(dto)));

        setInterval(() => this.updateNow(), 15 * 1000);
    }

    private updateNow() {
        this.now = moment();
    }

    private addNewLog(stationState: IStationState, isInitial: boolean) {

        // The initial state is already in the logs, so we skip it
        if (isInitial) {
            return;
        }

        let timestamp = moment();

        // Directly updating this.logs does not trigger a Vue change, so we recreate the whole dictionary
        let logs = this.logEntries;
        logs.unshift({ timestamp, stationState: stationState });
        this.logs = this.toDictionary(logs);

        this.updateNow(); // Ensure a correct calculation of the 'time from' text
    }

    private updateAlarmStateLogs(logs: IStationStateLog[]) {
        this.logs = this.toDictionary(logs);
    }

    private toDictionary(logs: IStationStateLog[]) {

        let dict: { [timestamp: string]: IStationStateLog; } = {};

        logs.forEach(l => { dict[l.timestamp.toISOString()] = l; });
        return dict;
    }

    public get logEntries() {
        return Object.keys(this.logs).map(key => this.logs[key]);
    }

    public isToday(timestamp: Moment) {
        return timestamp.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');
    }

    public getStateText(actuatorState: IAlarmActuatorState) {
        if (!actuatorState.alarmStateKnown) {
            return 'Alarmzustand unbekannt';
        }
        if (actuatorState.activeAlarms.length === 0) {
            return 'kein Alarm';
        }
        return actuatorState.activeAlarms.map(a => this.getAlarmTypeText(a)).join(", ");
    }

    public getAlarmTypeText(alarmType: AlarmType) {
        switch (alarmType) {
            case AlarmType.AvalancheMovement:
                return 'Lawine in Bewegung';
            case AlarmType.AvalancheInDangerZone:
                return 'Lawine in Gefahrenbereich';
            case AlarmType.Sensalpin:
                return 'Sensalpin-Alarm';
        }
    }
}

import 'bootstrap';
import './styles.scss';

import Vue from "vue";
import App from "./components/app";

import * as es6Polyfill from 'es6-promise';

import BootstrapVue from 'bootstrap-vue';

import * as moment from 'moment';
import 'moment-timezone';
require('moment/locale/de');

const queryString = require('query-string');

import {IAppConfig} from './app-config';

es6Polyfill.polyfill();

Vue.use(BootstrapVue);

const query = queryString.parse(location.search);

moment.locale('de');
moment.tz.setDefault(query.timezone || null);

declare var webpackAppConfig: any;

const appConfig: IAppConfig = {
    version: webpackAppConfig.version,
    stationLinkBaseUrl: webpackAppConfig.stationLinkBaseUrl
};

const vue = new Vue({
    el: "#vue-app",
    template: `<app></app>`,
    components: {
        App
    },
    provide: {
        appConfig: appConfig
    }
});
